$(function() {
  $.ajax({
	url: '../../feed.php',
	xmlType: 'xml',
	success: function(xml) {
			let row = 0;
			let data = [];
			let nodeName;
			let output = $('.home_news-inner');  //出力先
			let attributes;
			let result;
			const month_english_list = ['Jan ','Feb ','Mar ','Apr ','May','June','July','Aug ','Sept ','Oct ','Nov ','Dec ']

			// start item 成形
			$(xml).find('item').each(function() {
				data[row] = {};
				$(this).children().each(function() {
				nodeName = $(this)[0].nodeName;
				data[row][nodeName] = {};
				attributes = $(this)[0].attributes;

				for (var i in attributes) {
				  data[row][nodeName][attributes[i].name] = attributes[i].value;
				}
				  data[row][nodeName]['text'] = $(this).text();
				});

				row++;
			});
			// end item 成形
			//年の整形
			const dateFormat_year = (date)=>{
				// 日付の変換
				let dateStr = new Date(date);
				// 年の取得
				let year = dateStr.getFullYear();
				return year;
			}
			//序数の整形
			const getGetOrdinal = (n)=>{
				let s=["th","st","nd","rd"],
				v = n%100;
				return n+(s[(v-20)%10]||s[v]||s[0]);
			}
			//月日の整形
			const dateFormat_month = (date)=>{
				// 日付の変換
				let dateStr = new Date(date);
				// 月の取得
				let month = month_english_list[dateStr.getMonth()];
				// 日の取得
				let day = getGetOrdinal(dateStr.getDate());
				return `${month}${day}`;
			}

			// for (let i in data) {
			//表示件数指定の場合
			for(let i = 0; i < 1; i++){
			  let date = (data[i]) ? data[i].pubDate.text : '';
			  let link = (data[i]) ? data[i].link.text : '';
			  let title = (data[i]) ? data[i].title.text : '';

			  //適宜編集
			  result = `
				<div class="home_news-date">
					<div class="home_news-month">${dateFormat_month(date)}</div>
					<div class="home_news-year">${dateFormat_year(date)}</div>
				</div>
				<div class="home_news-text"><a href="${link}" target="_blank" >${title}</a></div>
			  `;
			  // output.append(result);
			}
        }
    });
});
